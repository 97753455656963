export const useFormatPresence = () => {
  const getColorFromAvailability = (availability: string): string => {
    switch (availability) {
      case "Available":
        return "#44b700";
      case "Busy":
      case "DoNotDisturb":
        return "#cc0000";
      case "BeRightBack":
      case "Away":
        return "#f1c232";
      case "Offline":
      default:
        return "#bcbcbc";
    }
  };

  const getStatusFromActivity = (activity: string): string => {
    switch (activity) {
      case "Available":
        return "Disponível";
      case "Busy":
        return "Ocupado";
      case "InACall":
        return "Ao Telefone";
      case "DoNotDisturb":
        return "Não incomodar";
      case "Presenting":
        return "Apresentando";
      case "BeRightBack":
        return "Volto logo";
      case "Away":
        return "Aparecer como ausente";
      case "Offline":
        return "Invisível";
      default:
        return activity;
    }
  };
  return { getColorFromAvailability, getStatusFromActivity };
};
