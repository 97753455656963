import { useEffect, useState } from "react";
import { useUserService } from "../services/user";
import { useFormatPresence } from "../utils/useFormatPresence";

export const usePresence = () => {
  const { getPresence } = useUserService();
  const {getColorFromAvailability, getStatusFromActivity} = useFormatPresence();
  const [colorPresence, setColorPresence] = useState("");
  const [statusPresence, setStatusPresence] = useState("");

  useEffect(() => {
    const fetchPresence = async () => {
      try {
        const presence = await getPresence();
        if (presence) {
          setColorPresence(getColorFromAvailability(presence.availability));
          setStatusPresence(getStatusFromActivity(presence.activity));
        }
      } catch (error) {
        // Tratar erros, se necessário
      }
    };

    fetchPresence();
  }, [getColorFromAvailability, getPresence, getStatusFromActivity]);

  return { colorPresence, statusPresence };
};
