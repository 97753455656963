import { useState } from "react";

export const useFormDialog = (): [boolean, string, (title: string) => void, () => void] => {
  const [isOpenFormDialog, setIsOpenFormDialog] = useState(false);
  const [titleFormDialog, setTitleFormDialog] = useState("");

  const openFormDialog = (title: string) => {
    setTitleFormDialog(title);
    setIsOpenFormDialog(true);
  };

  const closeFormDialog = () => {
    setTitleFormDialog("");
    setIsOpenFormDialog(false);
  };

  return [isOpenFormDialog, titleFormDialog, openFormDialog, closeFormDialog];
};
