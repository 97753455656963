/* eslint-disable @typescript-eslint/no-use-before-define */
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export interface FormDialogProps {
  title: string;
  isOpen: boolean;
  yes: () => void;
  no: () => void;
}

const DeleteFormDialog = (props: FormDialogProps) => {
  return (
    <Dialog      
      open={props.isOpen}
      onClose={() => props.no()}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>Tem certeza que deseja excluir?</DialogContent>
      <DialogActions>
        <Button onClick={props.yes} color="error">
          Sim
        </Button>
        <Button onClick={props.no}>Não</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteFormDialog;
